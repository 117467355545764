import logo from './logo.svg';
import './App.css';
import Drawer from './components/Drawer';
import { BrowserRouter, Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './pages/Login'
import { ToastContainer } from 'react-toastify';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import store from './store/store'
import { useEffect } from 'react';
import { SET_AUTH } from './store/types';
function App() {
  const { auth, ui } = useSelector(state => state)
  const dispatch = useDispatch()
  useEffect(async () => {
    if (localStorage.getItem('session') && !auth.auth) {
      let stored = await JSON.parse(localStorage.getItem('session'))
      dispatch({
        type: SET_AUTH, payload: {
          token: stored.token,
          user: stored.user
        }
      })
    }
  }, [auth])
  console.log(auth)
  return (
    <BrowserRouter>
      <div >
        <ToastContainer />
        <Backdrop style={{ zIndex: 2500, color: "#fff" }} open={ui.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
     
          {/* <Route
            exact
            path="/"
            render={() => {
              return (
                (auth.auth || localStorage.getItem('session')) ?
                  <Redirect to="/dashboard" />
                  :
                  <Redirect to="/login" />
              )
            }}
          /> */}
          {!(auth.auth || localStorage.getItem('session')) &&

            <>
            
        <Switch>
              <Route
              exact
                path="/login"
              >
                <Login />
              </Route>
              <Route
              exact
                path="/resetPassword"
                render={(props)=><Login {...props} type={1}/>}
             / >
              <Redirect from="*" to="/login" />
          
        </Switch>
            </>
          }
          {(auth.auth || localStorage.getItem('session')) &&
            <>
            
        <Switch>
               <Route
              exact
                path="/resetPassword"
                render={(props)=><Login {...props} type={1}/>}
             / >
                
             
              <Route path="/dashboard">
                <Drawer />
              </Route>
           
              <Route
                exact
                path="/"
                render={() => {
                  return (
                      <Redirect to="/dashboard" />
                  )
                }}
              />
              
        </Switch>
            </>}


      </div>
    </BrowserRouter>
  );
}
export default App;
