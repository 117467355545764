import { apis } from '../apis';
import client from '../client';
import store from '../store';
import { LOADING, STOP_LOADING } from '../types';

export function getBusinessDetails(){
    return new Promise((resolve, reject) => {
        store.dispatch({ type: LOADING })
        client.get(`${apis.fetchBusinessDetails}`)
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}
export function updateBusinessDetails(data){
    return new Promise((resolve,reject)=>{
        store.dispatch({ type:LOADING})
        client.put(`${apis.updateBusinessDetails}`,data)
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}



export function resetPasswordMail(email){
    return new Promise((resolve, reject) => {
store.dispatch({ type: LOADING })
client.post(`${apis.resetPasswordMail}`,{ email: email}).then(response=>{
    store.dispatch({ type:STOP_LOADING})
    resolve(response.data)
}).catch(err=>{
    return reject(err)
})
    })

}
