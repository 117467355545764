export const apis= {
    login:"api/user/login",
    fetchUsers:"/api/user/all",
    fetchSingleUser:"/api/user/get/",
    createUser:"/api/user/create",
    updateUser:"/api/user/update/",
    deleteUser:"/api/user/delete/",
    createService:"/api/service/create",
    fetchSingleService:"/api/service/details/",
    updateService: "/api/service/update/",
    fetchServices:"/api/service/get",
    deleteService:"/api/service/delete/",
    getServiceBookings:"/api/booking/available/",
    fetchBusinessDetails:"/api/business/details",
    updateBusinessDetails:"/api/business/update",
    getBookingDetails:"/api/booking/getDetails/",
    addBooking:"/api/booking/create",
    cancelBooking:"/api/booking/cancel/",
    markComplete:"/api/booking/complete/",
    getStats:"api/business/stats",
    uploadFile:"api/misc/upload",
    getSignedUrl:"api/misc/signed",
  getAllBookings:"/api/business/getallbookings",
    getCalender:"api/calender/get",
    createCalendar:"api/calender/createUpdate",
    updateCalendar:"api/calender/update",
    updateCalendarStatus:"api/calender/updateStatus",
    deleteCalendar:"api/calender/delete",


    resetPasswordMail:"api/mail/reset-password",
    resetPassword: "api/user/resetPassword"
}