import { apis } from '../apis';
import client from '../client';
import store from '../store';
import { LOGGED_IN, LOADING, STOP_LOADING, USERS_FETCHED, LOGGED_OUT, STATS_FETCHED } from '../types';
/**
 * Login
 * @param username : Email of user
 * @param password : Password
 */
export function login(username, password) {
    return new Promise((resolve, reject) => {
        store.dispatch({type:LOADING})
        client.post(`${apis.login}`,
            {
                email: username,
                password: password
            })
            .then(response => {
                response.data.token ??
                store.dispatch({type:LOGGED_IN,payload:{
                    token:response.data.token,
                    user:response.data.userData
                }})
                store.dispatch({type:STOP_LOADING})
                localStorage.setItem('session',JSON.stringify({
                    token:response.data.token,
                    user:response.data.userData
                }))
                resolve(response.data)
            })
            .catch(err => {
                store.dispatch({type:STOP_LOADING})
                store.dispatch({type:LOGGED_OUT,payload:{
                    token:null,
                    user:null
                }})
                reject(err)
            })
    })
}
export function createUser(params){
    return new Promise((resolve, reject) => {
        store.dispatch({type:LOADING})
        client.post(`${apis.createUser}`,
            params)
            .then(response => {
                // store.dispatch({type:LOGGED_IN,payload:{
                //     token:response.data.token,
                //     user:response.data.userData
                // }})
                store.dispatch({type:STOP_LOADING})
                console.log(response.data)
                resolve(response.data)
            })
            .catch(err => {
                store.dispatch({type:STOP_LOADING})
                // store.dispatch({type:LOGGED_IN,payload:{
                //     token:null,
                //     user:null
                // }})
                reject(err)
            })
    })
}
export const fetchUsers = ()=>{
    return new Promise((resolve,reject)=>{
        store.dispatch({type:LOADING})
        client.get(`${apis.fetchUsers}`)
        .then(response=>{
            store.dispatch({type:STOP_LOADING})
            store.dispatch({type:USERS_FETCHED,payload:response.data.data})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({type:STOP_LOADING})
            reject(err)
        })
    })
}
export const fetchSingleUser = (id)=>{
    return new Promise((resolve,reject)=>{
        store.dispatch({type:LOADING})
        client.get(`${apis.fetchSingleUser+id}`)
        .then(response=>{
            store.dispatch({type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({type:STOP_LOADING})
            reject(err)
        })
    })
}
export function updateUser(id,params){
    return new Promise((resolve, reject) => {
        store.dispatch({type:LOADING})
        client.put(`${apis.updateUser+id}`,
            params)
            .then(response => {
                store.dispatch({type:STOP_LOADING})
                console.log(response.data)
                resolve(response.data)
            })
            .catch(err => {
                store.dispatch({type:STOP_LOADING})
                console.log(err)
                reject(err)
            })
    })
}

export function logout(){
    return new Promise((resolve,reject)=>{
        localStorage.removeItem('session')
        store.dispatch({type:LOGGED_OUT})
        resolve()
    })
}

export function deleteUser(id){
    return new Promise((resolve,reject)=>{
        client.delete(`${apis.deleteUser+id}`)
        .then(response=>{
            resolve(response.data)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export function getStats(){
    // store.dispatch({type:LOADING})
    return new Promise((resolve,reject)=>{
        client.get(`${apis.getStats}`)
        .then(response=>{
            // store.dispatch({type:STOP_LOADING})
            store.dispatch({type:STATS_FETCHED,payload:response.data})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({type:STOP_LOADING})
            reject(err)
        })
    })
}


export const resetPassword=(token,password) =>{
return new Promise((resolve,reject)=>{
    store.dispatch({type:LOADING})
    client.post(`${apis.resetPassword}`,{token:token,password:password}).then(
        response =>{
            store.dispatch({type:STOP_LOADING})
            resolve("success")
        }
    ).catch(err=>{
        store.dispatch({type:STOP_LOADING})
        reject(err)
    })
})
}