import React from 'react'
import PeopleIcon from '@material-ui/icons/People';
import CalendarToday from '@material-ui/icons/CalendarToday';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import SettingsIcon from '@material-ui/icons/Settings';
//import CreateUser from './views/CreateUser'
const CreateUser=React.lazy(()=>import('./views/users/CreateUser'))
const EditUser=React.lazy(()=>import('./views/users/EditUser'))
const Users=React.lazy(()=>import('./views/users/Users'))
const Calender=React.lazy(()=>import('./views/services/CalenderIndex'))
const Services=React.lazy(()=>import('./views/services/Services'))
const EditService= React.lazy(()=>import('./views/services/EditService'))
const CreateService= React.lazy(()=>import('./views/services/CreateService'))
const Booking= React.lazy(()=>import('./views/services/Bookings'))
const Settings= React.lazy(()=>import('./views/business/Settings'))
const Profile= React.lazy(()=> import('./views/profile/Profile'))
const Allbookings= React.lazy(()=>import('./views/services/AllBookings'))
const TodayBook= React.lazy(()=>import('./views/services/TodayBooking'))
export default [
    {path:'/dashboard/users',component:Users,name:'Users',sidebar:true,icon:<PeopleIcon />},
    {path:'/dashboard/profile',component:Profile,name:'Profile'},
    {path:'/dashboard/settings',component:Settings,name:'Settings',sidebar:true,icon:<SettingsIcon />},
    {path:'/dashboard/users/create',component:CreateUser,name:'Create User'},
    {path:'/dashboard/users/edit/:id',component:EditUser,name:'Edit User'},
    {path:'/dashboard/services',component:Services,name:'Services',sidebar:true,icon:<AddToQueueIcon />},
    {path:'/dashboard/services/create',component:CreateService,name:'Create Service',sidebar:false},
    {path:'/dashboard/services/edit/:id',component:EditService,name:'Edit Service'},
    {path:'/dashboard/services/bookings/:id',component:Booking,name:'Bookings'},
    {path:'/dashboard/services/Allbookings',component:Allbookings,name:'Bookings'},
    {path:'/dashboard/services/Todaysbookings',component:TodayBook,name:'Today Bookings'},
    {path:'/dashboard/services/calender/:id',component:Calender,name:'Calender',icon:<CalendarToday />}
]