import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, withTheme } from '@material-ui/core/styles';
import ContentCard from './ContentCard';
import routes from '../routes'
import { Route, Switch } from 'react-router-dom';
import { Link, useHistory, useLocation, Redirect,matchPath } from 'react-router-dom';
import { CircularProgress, Avatar, Box, Breadcrumbs } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { logout } from '../store/actions/authactions';
import { toast } from 'react-toastify';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HomeIcon from '@material-ui/icons/Home';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSelector } from 'react-redux';

const Dashboard = React.lazy(() => import('../views/users/Dashboard'))
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    alignItems: "center",
    display: 'flex',
    justifyContent: "center",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  brand: {
    color: "white"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logout: {
    color: theme.palette.error.dark
  },
  breadIcon: {
    marginRight: theme.spacing(0.5),
    width: 15,
    height: 15,
  },
  breadcrumbLink:{
    textDecoration:"none",
    fontWeight:600,
  }
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const user = useSelector(state=>state.auth.user)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const handleLogout = () => {
    if (window.confirm("Are you Sure to Logout?")) {
      logout()
        .then(() => {
          toast('You have been logged out');
          history.push('/login')
        })
    }

  }
  let history = useHistory();
  let location = useLocation();
  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <h1>BeatTheQ</h1>
        </Link>
      </div>
      <Divider />
      <List>
        {/* <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Inbox" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem> */}
        {/* <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Starred" />
          </ListItem>
        </List>
      </Collapse> */}
        <ListItem selected={location.pathname == '/dashboard' ? true : false} button key='dashboard' onClick={() => history.push('/dashboard')}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </ListItem>
        
        {routes.map((text, index) => (
          text.sidebar ?
            <ListItem selected={location.pathname.match(text.path) ? true : false} button key={text.name} onClick={() => history.push(text.path)}>
              <ListItemIcon>{text.icon ? text.icon : null}</ListItemIcon>
              <ListItemText primary={text.name} />
            </ListItem>
            : null
        ))}
        <Box m={4}/>
        <Divider />
        <ListItem selected={false} button key='visit' onClick={() => window.open(`https://app.beattheq.ie/${user ? user.business.shortUrl : ''}`,'_blank')}>
          <ListItemIcon><VisibilityIcon /></ListItemIcon>
          <ListItemText>Visit Business</ListItemText>
        </ListItem>
        <Divider />

        <ListItem button onClick={handleLogout}>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText className={classes.logout}>Logout</ListItemText>
        </ListItem>
      </List>
      <Divider />
      
    </div>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/dashboard" variant="h2" className={classes.brand} underline="none">
            <Typography variant="h6" noWrap>
              Dashboard
          </Typography>
          </Link>
          <div style={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
            <Avatar onClick={handleAvatarClick}></Avatar>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => history.push('/dashboard/profile')}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
           // container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link className={classes.breadcrumbLink} style={{color:location.pathname=='/dashboard' ? theme.palette.text.primary : theme.palette.text.secondary}} color="inherit" onClick={()=>history.push('/dashboard')}>
                <HomeIcon className={classes.breadIcon} />  Home
          </Link>
            {routes.map((item, key) => (
              matchPath(location.pathname,{path:item.path,exact:false,strict:false}) ?
            //  location.pathname.match(item.path) ?
                <Link className={classes.breadcrumbLink} style={{color:matchPath(location.pathname,{path:item.path,exact:true,strict:false}) ? theme.palette.text.primary : theme.palette.text.secondary}} onClick={()=>history.push(item.path)}>
                  {item.name}
                </Link> : null
            ))}
          </Breadcrumbs>
          <ContentCard>
            <React.Suspense fallback={<CircularProgress />}>
              <Switch>
                {routes.map((route, index) => (
                  <Route path={route.path} component={route.component} key={index} exact></Route>
                ))}
                <Route path="/dashboard" component={Dashboard} key="dashboard" exact></Route>
                <Redirect from="*" to="/dashboard" />
              </Switch>
            </React.Suspense>
          </ContentCard>
        </div>
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
